import {
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { FileSearchResults, FileSearchResultsCodec } from 'app/codecs'
import { ReactComponent as CaretIcon } from 'assets/icons/caret.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search-file.svg'
import { Input } from 'components/Input'
import { concatQueryParams } from 'lib/request'
import { useJsonQuery } from 'lib/rest-query'
import debounce from 'lodash.debounce'
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const SearchPopper = ({
  taskId,
  value,
  translation,
  onChangeSearch,
  onSearchNavigate,
}: {
  taskId: string
  value: string
  translation: string | null
  onChangeSearch: (search: string, results: FileSearchResults | null) => void
  onSearchNavigate: (element: number | null) => void
}) => {
  const { t } = useTranslation()

  const [input, setInput] = useState(value)
  const [search, setSearch] = useState(value)
  const [current, setCurrent] = useState<number | null>(null)

  const button = useRef<HTMLButtonElement>(null)

  const popper = usePopupState({
    popupId: 'fileSearch',
    variant: 'popper',
  })

  const params = useMemo(() => {
    const params = new URLSearchParams({ chunksLimit: '200' })

    if (search.length > 0) {
      params.set('searchText', search)
    }

    if (translation) {
      params.set('language', translation)
    }

    return params
  }, [search, translation])

  const $search = useJsonQuery(
    'GET',
    concatQueryParams(`/api/tasks/${taskId}/text-search`, params),
    FileSearchResultsCodec,
    {
      options: {
        enabled: search.trim().length > 0 && input.trim().length > 0,
        onSuccess: data => {
          setCurrent(0)
          onChangeSearch(search, data)
          onSearchNavigate(0)
        },
      },
    },
  )

  const { data, isLoading, remove } = $search

  const onInputChange = useMemo(() => {
    return debounce(setSearch, 400)
  }, [setSearch])

  const noSearch = current === null || !data || data.length === 0
  const disabledNavigation = noSearch || data.length === 1

  const handleClose = () => {
    popper.close()
    setInput('')
    setCurrent(null)
    onChangeSearch('', null)
    onSearchNavigate(null)
    remove()
  }
  useEffect(() => {
    if (value !== '') {
      setInput(value)
      setSearch(value)
      popper.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  const handlePrevious = () => {
    if (noSearch) {
      return
    }

    setCurrent(element => {
      if (element === null) {
        return null
      }

      const prev = element === 0 ? data.length - 1 : element - 1

      onSearchNavigate(prev)
      return prev
    })
  }

  const handleNext = () => {
    if (noSearch) {
      return
    }

    setCurrent(element => {
      if (element === null) {
        return element
      }

      const next = element === data.length - 1 ? 0 : element + 1

      onSearchNavigate(next)
      return next
    })
  }

  useEffect(() => {
    handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translation])

  useLayoutEffect(() => {
    if (
      search.trim().length > 0 &&
      search.trim().length === input.trim().length &&
      !popper.isOpen
    ) {
      popper.setOpen(true)
    }
  }, [input, popper, search])

  return (
    <>
      <Tooltip title={t('labels.search')}>
        <ButtonBase
          {...bindToggle(popper)}
          ref={button}
          sx={{ padding: '10px', borderRadius: '43px' }}
          onClick={event => {
            bindToggle(popper).onClick(event)
            setCurrent(null)
            setInput('')
            onChangeSearch('', null)
            onSearchNavigate(null)
            remove()
          }}
        >
          <SearchIcon color="#37637E" />
        </ButtonBase>
      </Tooltip>

      <Popper
        {...bindPopper(popper)}
        anchorEl={button.current?.parentElement ?? undefined}
        placement="bottom-end"
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: 1,
            background: '#FFFFFF',
            border: '1px solid #F3F7F9',
            boxShadow: '0px 4px 8px rgba(8, 15, 52, 0.06)',
            borderRadius: '26px',
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              event.shiftKey ? handlePrevious() : handleNext()
            }

            if (event.key === 'Escape') {
              event.preventDefault()
              handleClose()
            }

            if (event.key === ' ') {
              event.stopPropagation()
            }
          }}
        >
          <Input
            autoFocus
            multiline={false}
            value={input}
            onChange={e => {
              setCurrent(null)
              onChangeSearch('', null)
              onSearchNavigate(null)
              setInput(e.target.value)
              onInputChange(e.target.value)
            }}
            fullWidth
            inputProps={{
              style: {
                paddingLeft: 20,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '22px',
                color: 'text.primary',
              },
            }}
            sx={{
              height: 40,
              pr: 1,
            }}
            componentsProps={{
              root: {
                style: { border: 'none', width: 300 },
              },
            }}
            endAdornment={
              input.trim().length > 0 ? (
                isLoading ? (
                  <Box display="flex" justifyContent="center" px={1}>
                    <CircularProgress color="info" size={18} />
                  </Box>
                ) : (
                  <Typography
                    px={1.5}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="22px"
                    color="text.secondary"
                  >
                    {noSearch ? 0 : current + 1}/{data?.length ?? 0}
                  </Typography>
                )
              ) : undefined
            }
          />
          <Divider
            sx={{ my: '4px !important' }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Tooltip title={disabledNavigation ? '' : t('buttons.previous')}>
            <ButtonBase
              sx={{
                padding: '10px',
                borderRadius: '43px',
              }}
              disabled={disabledNavigation}
              onClick={() => handlePrevious()}
            >
              <CaretIcon color={disabledNavigation ? '#87A1B2' : '#37637E'} />
            </ButtonBase>
          </Tooltip>
          <Tooltip title={disabledNavigation ? '' : t('buttons.next')}>
            <ButtonBase
              sx={{
                padding: '10px',
                borderRadius: '43px',
              }}
              disabled={disabledNavigation}
              onClick={() => handleNext()}
            >
              <CaretIcon
                color={disabledNavigation ? '#87A1B2' : '#37637E'}
                style={{ transform: 'rotate(180deg)' }}
              />
            </ButtonBase>
          </Tooltip>
          <Tooltip title={t('buttons.close')}>
            <ButtonBase
              sx={{ padding: '10px', borderRadius: '43px' }}
              onClick={() => handleClose()}
            >
              <CloseIcon color="#37637E" />
            </ButtonBase>
          </Tooltip>
        </Stack>
      </Popper>
    </>
  )
}
