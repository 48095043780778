import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const RoleNames = t.union([
  t.literal('EDITOR'),
  t.literal('ADMIN'),
  t.literal('SYSTEM_ADMIN'),
  t.literal('GUEST'),
  t.literal('OWNER'),
  t.literal('ORG_ADMIN'),
])

export const TUserStatus = t.union([
  t.literal('ACTIVE'),
  t.literal('DEACTIVATED'),
  t.literal('PENDING'),
  t.literal('REMOVED'),
])

export const UserCodec = t.type({
  avatarUrl: t.union([t.string, t.null]),
  userId: t.string,
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  duration: t.union([t.number, t.null]),
  userStatus: TUserStatus,
  creditsLimit: t.union([t.number, t.null]),
  role: RoleNames,
  organizationId: t.union([t.string, t.undefined]),
  removedAt: t.union([t.string, t.null]),
  createdAt: DateFromISOString,
})

export const RoleNameCodec = t.type({
  roleName: RoleNames,
})

export const ManagerCodec = t.type({
  userId: t.string,
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  role: RoleNameCodec,
  userStatus: TUserStatus,
  createdAt: t.string,
  creditsLimit: t.number,
  removedAt: t.union([t.string, t.null]),
})

export const UserForOrganizationCodec = t.type({
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  duration: t.union([t.number, t.null]),
  active: t.boolean,
  role: t.string,
})

export const UsersForOrganizationCodec = t.array(UserForOrganizationCodec)

export const UsersCodec = t.array(UserCodec)

export type Users = t.TypeOf<typeof UsersCodec>

export type User = t.TypeOf<typeof UserCodec>

export type Roles = t.TypeOf<typeof RoleNames>

export type UserStatus = t.TypeOf<typeof TUserStatus>
