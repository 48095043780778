import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'app/auth'
import { CreditsCodec, OrganizationCodec, ProfileCodec } from 'app/codecs'
import { useCreateTaskContext } from 'app/create-task'
import { ReactComponent as NotificationIcon } from 'assets/icons/bell.svg'
import { ReactComponent as ClearIcon } from 'assets/icons/clear.svg'
import { ReactComponent as Credits } from 'assets/icons/credits.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { StyledAvatar, Tooltip } from 'components'
import { InternalLink } from 'components/InternalLink'
import { NotificationCircularProgress } from 'components/NotificationsCircularProgress'
import { formatDuration } from 'imgplay-domain/format'
import { useJsonQuery } from 'lib/rest-query'
import { AuthUserRoles } from 'lib/use-auth/codecs'
import {
  bindMenu,
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AdminEditorMenuItems,
  IMenuItem,
  OwnerMenuItems,
  SystemAdminMenuItems,
} from './menuDrawerItems'

type Props = {
  firstName: string
  lastName: string
  avatarUrl: string | null
  role: 'SYSTEM_ADMIN' | 'ORG_ADMIN' | 'ADMIN' | 'EDITOR' | 'OWNER'
}

const menuItemStyles = {
  borderRadius: '0px',
  color: 'black',
  '&:focus': {
    backgroundColor: 'white',
    color: 'black',
  },
  '&:hover': {
    backgroundColor: 'info.light',
  },
}

export const ProfileMenu: FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { signOut, accessToken } = useAuthContext()
  const { trackProgress } = useCreateTaskContext()

  const menu = usePopupState({
    popupId: 'profileMenu',
    variant: 'popover',
  })

  const popover = usePopupState({
    popupId: 'notifications',
    variant: 'popover',
  })

  const trackProgressFiltered = trackProgress?.filter(
    track => track.percentage !== 100,
  )

  useEffect(() => {
    // To trigger uploading tracker popover when the uploading of the new file starts
    if (
      trackProgress &&
      trackProgress[trackProgress.length - 1].percentage === 0
    ) {
      popover.open()
    }
  }, [popover, trackProgress])

  const $credits = useJsonQuery(
    'GET',
    '/api/organizations/my/credits',
    CreditsCodec,
  )
  const $profile = useJsonQuery('GET', '/api/users/me', ProfileCodec)

  const $organization = useJsonQuery(
    'GET',
    '/api/organizations/my',
    OrganizationCodec,
  )
  const { data } = $credits

  const limit = $profile?.data?.creditsLimit
  const creditsLeft = $profile?.data?.availableCredits

  const profile = useMemo(
    () => ({
      fullName: `${props.firstName} ${props.lastName}`,
      defaultAvatar: `${props.firstName.charAt(0)}${props.lastName.charAt(0)}`,
      role: accessToken ? t(`roles.${accessToken.role}`) : '',
    }),
    [accessToken, props.firstName, props.lastName, t],
  )

  if (accessToken === null) {
    return null
  }

  const items: Record<AuthUserRoles, IMenuItem[]> = {
    SYSTEM_ADMIN: SystemAdminMenuItems,
    OWNER: OwnerMenuItems,
    ADMIN: AdminEditorMenuItems,
    EDITOR: AdminEditorMenuItems,
    ORG_ADMIN: SystemAdminMenuItems,
  }

  return (
    <Stack direction="row" alignItems="center">
      {data !== undefined && (
        <Stack direction="row" mr={'28px'}>
          {props.role !== 'SYSTEM_ADMIN' && props.role !== 'ORG_ADMIN' && (
            <>
              {!(!limit && props.role === 'EDITOR') && (
                <Box mr={'8px'}>
                  <Credits />
                </Box>
              )}

              <Tooltip
                title={
                  limit ? (
                    <Typography variant="caption">
                      {t('tooltips.total_credits')}
                    </Typography>
                  ) : (
                    <Typography variant="caption">
                      {t('tooltips.available_organization_credits')}
                    </Typography>
                  )
                }
              >
                <Typography
                  align="center"
                  sx={{
                    color: 'white',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: '400',
                    letterSpacing: '0px',
                    textAlign: 'left',
                    cursor: 'pointer',
                  }}
                >
                  {limit
                    ? `${formatDuration(limit)} \\ \u00A0`
                    : props.role !== 'EDITOR' && formatDuration(data)}
                </Typography>
              </Tooltip>
            </>
          )}
          <Tooltip
            title={
              <Typography variant="caption">
                {t('tooltips.left_credits')}
              </Typography>
            }
          >
            <Typography
              align="center"
              sx={{
                color: 'white',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                fontWeight: '400',
                letterSpacing: '0px',
                textAlign: 'left',
                cursor: 'pointer',
              }}
            >
              {creditsLeft ? ` ${formatDuration(creditsLeft)}` : null}
            </Typography>
          </Tooltip>
        </Stack>
      )}
      <Hidden mdDown>
        <StyledAvatar src={props.avatarUrl ?? undefined}>
          {profile.defaultAvatar}
        </StyledAvatar>

        <Tooltip
          title={
            $organization.data
              ? `${t('organizations.account_id')} ${
                  $organization.data.accountId
                }`
              : ''
          }
        >
          <Grid
            container
            pl={1}
            flexDirection="column"
            sx={{ mt: -0.5, mr: 2, cursor: 'pointer' }}
          >
            <Grid item xs={12}>
              <Typography
                noWrap
                align="left"
                sx={{ color: 'white', fontSize: '14px', fontWeight: 400 }}
              >
                {profile.fullName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                noWrap
                align="left"
                sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}
              >
                {$organization.data?.name} \ {profile.role}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={
            <Typography variant="caption">
              {t('menu_items.notifications')}
            </Typography>
          }
        >
          <Box position="relative" {...bindTrigger(popover)}>
            <IconButton>
              <NotificationIcon />
            </IconButton>
            {Boolean(trackProgressFiltered?.length) && (
              <NotificationCircularProgress />
            )}
          </Box>
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={
            <Typography variant="caption">{t('menu_items.menu')}</Typography>
          }
        >
          <IconButton {...bindTrigger(menu)} size="small" disableRipple>
            <MoreVertIcon style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
        <Menu
          {...bindMenu(menu)}
          sx={{ top: '4px', '.MuiList-root': { padding: '0px' } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {items[accessToken.role].map(({ Icon, to, id, title }) => (
            <Box key={id}>
              <InternalLink to={to} sx={{ width: '100%' }}>
                <MenuItem
                  sx={{
                    paddingBlock: theme => theme.spacing(1.5),
                    paddingInline: '14.5px',
                    ...menuItemStyles,
                  }}
                  value={to}
                  onClick={menu.close}
                >
                  <Icon />
                  <Box ml={1.2} textTransform={'capitalize'}>
                    {t(`pages.${title}`)}
                  </Box>
                </MenuItem>
              </InternalLink>
              <Divider
                sx={{ backgroundColor: ({ palette }) => palette.info.light }}
              />
            </Box>
          ))}

          <MenuItem
            sx={{
              paddingBlock: theme => theme.spacing(1.5),
              paddingInline: '14.5px',
              ...menuItemStyles,
            }}
            onClick={signOut}
          >
            <Box
              color="inherit"
              alignItems="center"
              display="flex"
              textTransform={'capitalize'}
            >
              <LogoutIcon style={{ width: 18, height: 18 }} />
              <Box ml={1.4}>{t('menu_items.log_out')}</Box>
            </Box>
          </MenuItem>
        </Menu>

        <Popover
          {...bindPopover(popover)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ mt: 5 }}
        >
          <Box sx={{ minWidth: '350px' }}>
            <Box p={2}>
              <Typography variant="h3" fontSize={18} gutterBottom>
                {t('labels.uploads')}
              </Typography>
              {trackProgressFiltered &&
                trackProgressFiltered.map(track => {
                  return (
                    <Stack direction="column" mb={2} key={track.trackId}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="subtitle2">
                          {track.filename.length > 40
                            ? track.filename.slice(0, 40) + '...'
                            : track.filename}
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="subtitle2">
                            {track.percentage}%
                          </Typography>
                          {track.percentage < 100 && (
                            <IconButton onClick={track.abort}>
                              <ClearIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Stack>
                      <LinearProgress
                        variant="determinate"
                        sx={{
                          '&.MuiLinearProgress-colorPrimary': {
                            backgroundColor: 'info.main',
                            height: '2px',
                          },
                          '.MuiLinearProgress-bar': {
                            backgroundColor: 'secondary.dark',
                            height: '2px',
                          },
                        }}
                        value={track.percentage}
                      />
                    </Stack>
                  )
                })}

              {/* Adjust this block when notification functionality will be available */}
              {(!trackProgressFiltered ||
                trackProgressFiltered.length === 0) && (
                <Typography variant="body2" pt={1}>
                  {t('notifications.no_notifications')}
                </Typography>
              )}
            </Box>
          </Box>
        </Popover>
      </Hidden>
    </Stack>
  )
}
