import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Chip,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'app/auth'
import { IIntegrationType, ITaskLanguage } from 'app/codecs'
import { TagCodecArray } from 'app/codecs/tag'
import { routes } from 'app/routes'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as FontsIcon } from 'assets/icons/fonts.svg'
import { ReactComponent as LanguageIcon } from 'assets/icons/language.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as StarIcon } from 'assets/icons/star.svg'
import flags from 'assets/languages'
import flags2x from 'assets/languages/languages@2x'
import { KeyboardBackspace } from 'assets/SVGComponents'
import { CloseIcon } from 'assets/SVGComponents'
import { Button, Input, TranslationSelect } from 'components'
import { FormModal } from 'components/Form'
import { InternalLink } from 'components/InternalLink'
import { tagsColors } from 'components/TaskName/TaskName'
import { flagCodes } from 'config/flag-name-code'
import { integrationTypeMapping } from 'config/integration-options'
import { formatConfidenceScore } from 'imgplay-domain/format'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useBoolean } from 'lib/use-boolean'
import { useSnackbar } from 'notistack'
import { FC, ReactNode, SVGProps, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormValues = {
  tags: string[]
}

type Tags =
  | {
      tags: string[]
      taskId: string
      allowTagsEdit: boolean
      onTagsUpdate?: () => void
    }
  | {
      tags?: undefined
      taskId?: undefined
      allowTagsEdit?: undefined
      onTagsUpdate?: undefined
    }

type Props = {
  title: string
  buttonName?: string
  buttonLink?: string
  source?: string
  children: ReactNode
  widgets?: Array<ReactNode>
  Icon?: FC<SVGProps<SVGSVGElement>>
  externalLink?: string
  dictionary?: string | null
  language?: ITaskLanguage
  translations?: ITaskLanguage[]
  confidenceScore?: number | null
  wordsNumber?: number | null
  onTranslationChange?: (value: string | null) => void
} & Tags

export const PageContainer = ({
  title,
  widgets,
  children,
  source,
  buttonName,
  buttonLink,
  externalLink,
  dictionary,
  language,
  translations,
  confidenceScore,
  wordsNumber,
  tags,
  taskId,
  allowTagsEdit,
  onTagsUpdate,
  onTranslationChange: onTranslationChange,
}: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { accessToken } = useAuthContext()

  const dialog = useBoolean(false)

  const sourceName = useMemo(
    () =>
      integrationTypeMapping[source as IIntegrationType] || t('labels.file'),
    [source, t],
  )

  const renderedTags = useMemo(() => {
    const filteredTags = tags?.filter(tag => tag.length > 0)

    return filteredTags && filteredTags?.length > 0
      ? [...filteredTags.slice(0, 10), '...']
      : filteredTags
  }, [tags])

  const $allTags = useJsonQuery(
    'GET',
    '/api/organizations/tags',
    TagCodecArray,
    {
      options: { enabled: renderedTags !== undefined && allowTagsEdit },
    },
  )

  const $updateTags = useMutation('PUT', '/api/tags/:taskId')

  return (
    <Box minHeight={'calc(100vh - 113px)'} minWidth={'85vw'} maxWidth={'95vw'}>
      {buttonName && buttonLink && (
        <Box mt={1} mb={1} height="20px">
          <InternalLink to={buttonLink}>
            <Typography
              variant="body2"
              sx={{
                margin: '0px',
                display: 'flex',
                alignItems: 'center',
                color: '#87A1B2',
                zIndex: 1000,
                cursor: 'pointer',
              }}
            >
              <KeyboardBackspace style={{ marginRight: 7 }} /> {buttonName}
            </Typography>
          </InternalLink>
        </Box>
      )}

      <Grid container justifyContent="space-between" alignItems="start">
        <Grid item flexGrow={1}>
          {title !== '' && (
            <Grid container item xs={12} md={12} spacing={1}>
              <Grid item xs={12} md={12} mb={1} flexDirection="column">
                {taskId && (
                  <Grid item xs={12} mb={0.5}>
                    <Breadcrumbs sx={{ color: 'text.disabled', fontSize: 14 }}>
                      {accessToken &&
                      accessToken.role !== 'SYSTEM_ADMIN' &&
                      accessToken.role !== 'ORG_ADMIN' ? (
                        <InternalLink
                          to={routes.myFiles}
                          fontSize={14}
                          underline="hover"
                        >
                          {t('menu_items.files')}
                        </InternalLink>
                      ) : (
                        <Typography variant="inherit">
                          {t('menu_items.files')}
                        </Typography>
                      )}
                      <Typography variant="inherit">{title}</Typography>
                    </Breadcrumbs>
                  </Grid>
                )}
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography
                    variant="h1"
                    fontWeight="700"
                    sx={{
                      maxWidth: '90%',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      mb: 1.5,
                    }}
                  >
                    {title}
                  </Typography>
                  {language &&
                    language.name !== 'None' &&
                    (!translations || translations.length === 0) && (
                      <Box
                        width="32px"
                        height="24px"
                        overflow="hidden"
                        borderRadius="4px"
                        border="1px solid #CED7DE"
                      >
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'block',
                            objectFit: 'cover',
                          }}
                          loading="lazy"
                          // @ts-ignore
                          src={flags[flagCodes[language.name]]}
                          // @ts-ignore
                          srcSet={`${flags2x[flagCodes[language.name]]} 2x`}
                          alt={language.name}
                        />
                      </Box>
                    )}
                </Stack>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item flexShrink={1} alignItems="flex-start" justifyContent="end">
          {widgets && (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              {widgets}
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            // alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing="20px">
              <Stack
                direction="row"
                flex={1}
                alignItems="start"
                spacing={3}
                whiteSpace="nowrap"
                mb={1.5}
                pt={0.5}
              >
                {(externalLink || source) && (
                  <Stack direction="row" spacing={1}>
                    <SearchIcon />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      fontWeight={400}
                      lineHeight={1.25}
                    >
                      {t('labels.source')}:{' '}
                    </Typography>
                    {externalLink && (
                      <Link
                        variant="body1"
                        color="secondary.main"
                        lineHeight={1.25}
                        href={externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {sourceName}
                      </Link>
                    )}
                    {source && !externalLink && (
                      <Typography
                        variant="body1"
                        fontSize="16px"
                        lineHeight={1.25}
                      >
                        {sourceName}
                      </Typography>
                    )}
                  </Stack>
                )}
                {dictionary && (
                  <Stack direction="row" spacing={1}>
                    <LanguageIcon />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      fontWeight={400}
                      lineHeight={1.25}
                    >
                      {t('custom_dictionary.dictionary')}:{' '}
                    </Typography>
                    <Typography lineHeight={1.25}>{dictionary}</Typography>
                  </Stack>
                )}
                {typeof confidenceScore === 'number' && (
                  <Stack direction="row" spacing={1}>
                    <StarIcon />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      textTransform="capitalize"
                      fontWeight={400}
                      lineHeight={1.25}
                    >
                      {t('labels.confidence_score')}:{' '}
                    </Typography>
                    <Typography lineHeight={1.25}>
                      {formatConfidenceScore(confidenceScore)}
                    </Typography>
                  </Stack>
                )}
                {typeof wordsNumber === 'number' && (
                  <Stack direction="row" spacing={1}>
                    <FontsIcon />
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      textTransform="capitalize"
                      fontWeight={400}
                      lineHeight={1.25}
                    >
                      {t('labels.words_number')}:{' '}
                    </Typography>
                    <Typography lineHeight={1.25}>{wordsNumber}</Typography>
                  </Stack>
                )}
              </Stack>
              {language && translations && translations.length > 0 && (
                <Box mb={1}>
                  <TranslationSelect
                    sourceLanguage={language}
                    translations={translations}
                    onTranslationChange={value => {
                      onTranslationChange?.(value || null)
                    }}
                  />
                </Box>
              )}
            </Stack>
            {renderedTags !== undefined && (
              <Stack
                direction="row"
                spacing="6px"
                rowGap="6px"
                flexGrow={0}
                flexShrink={2}
                flexWrap="wrap"
                alignSelf="flex-start"
                justifyContent="end"
              >
                {allowTagsEdit ? (
                  <>
                    {renderedTags.length > 0 ? (
                      <>
                        {renderedTags.map((tag, idx) => (
                          <Tooltip
                            key={tag}
                            title={t('tooltips.click_to_edit')}
                          >
                            <span>
                              <Chip
                                label={tag}
                                size="small"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor:
                                    tagsColors[idx % tagsColors.length],
                                }}
                                onClick={() => dialog.setTrue()}
                              />
                            </span>
                          </Tooltip>
                        ))}
                      </>
                    ) : (
                      <Tooltip title={t('tooltips.click_to_add')}>
                        <span>
                          <Button
                            noSpacing
                            variant="text"
                            sx={{ padding: 0.5, height: 28, width: 42 }}
                            minWidth={42}
                            onClick={() => dialog.setTrue()}
                            size="small"
                          >
                            <AddIcon height={16} width={16} />
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <>
                    {renderedTags.length > 0
                      ? renderedTags.map((tag, idx) => (
                          <Chip
                            key={tag}
                            label={tag}
                            size="small"
                            sx={{
                              backgroundColor:
                                tagsColors[idx % tagsColors.length],
                            }}
                          />
                        ))
                      : '—'}
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} position="relative">
          {children}
        </Grid>
        {tags && allowTagsEdit && renderedTags && $allTags.data && (
          <FormModal<FormValues>
            onSubmit={({ tags }, { reset }) => {
              $updateTags.mutate(
                { params: { taskId }, body: { tags } },
                {
                  onSuccess: () => {
                    dialog.setFalse()
                    onTagsUpdate?.()
                    enqueueSnackbar(t('success_notification.tags_updated'), {
                      variant: 'success',
                    })
                    reset({ tags })
                  },
                  onError: () => {
                    enqueueSnackbar(
                      t('error_notification.error_tags_updating'),
                      { variant: 'error' },
                    )
                  },
                },
              )
            }}
            title={t('labels.edit_tags')}
            submitTitle={t('buttons.save')}
            onClose={dialog.setFalse}
            open={dialog.isTrue}
            isLoading={$updateTags.isLoading}
            defaultValues={{
              tags: tags || [],
            }}
            renderForm={({ control }) => (
              <Grid
                container
                onKeyDown={event => {
                  if (event.key === ' ') {
                    event.stopPropagation()
                  }
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="h4">{t('labels.tags')}</Typography>
                  <Controller
                    control={control}
                    name="tags"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        freeSolo
                        options={$allTags.data}
                        onChange={(_e, value) => field.onChange(value)}
                        sx={{ '.MuiInputBase-root': { paddingBottom: 0 } }}
                        renderTags={(options, getTagProps) =>
                          options.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              key={option + index}
                              label={option}
                              size="small"
                              deleteIcon={
                                <Box
                                  sx={{
                                    marginTop: '7px !important',
                                    marginRight: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <CloseIcon background={'#19445B'} />
                                </Box>
                              }
                              sx={theme => ({
                                background: theme.palette.primary.dark,
                                color: 'white',
                                fontWeight: 400,
                              })}
                            />
                          ))
                        }
                        renderInput={params => {
                          const { InputLabelProps, InputProps, ...rest } =
                            params
                          return <Input multiline {...InputProps} {...rest} />
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          />
        )}
      </Grid>
    </Box>
  )
}
