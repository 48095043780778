import {
  AppBar,
  Box,
  Button,
  Grid,
  Hidden,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'app/auth'
import { ProfileCodec } from 'app/codecs'
import { InternalLink } from 'components/InternalLink'
import { Loader } from 'components/Loader'
import { Tooltip } from 'components/Tooltip'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useBoolean } from 'lib/use-boolean'
import { useSnackbar } from 'notistack'
import {
  ChangeEvent,
  FC,
  ReactNode,
  Suspense,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { UploadVideoModal } from 'views'

import { ContentDrawer } from './ContentDrawer'
import { ContentMenu } from './ContentMenu'
import { LanguageMenu } from './LanguageMenu'
import {
  AdminDrawerItems,
  EditorDrawerItems,
  OrgAdminDrawerItems,
  OwnerDrawerItems,
  SystemAdminDrawerItems,
} from './menuDrawerItems'
import { ProfileMenu } from './ProfileMenu'

type Props = {
  children: ReactNode
}

const drawerItems = {
  EDITOR: EditorDrawerItems,
  OWNER: OwnerDrawerItems,
  ADMIN: AdminDrawerItems,
  SYSTEM_ADMIN: SystemAdminDrawerItems,
  ORG_ADMIN: OrgAdminDrawerItems,
}

export const AppAuthorizedLayout: FC<Props> = props => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const drawer = useBoolean(false)
  const { isTrue, setTrue, setFalse } = useBoolean(false)
  const $profile = useJsonQuery('GET', '/api/users/me', ProfileCodec)
  const $getMoreHours = useMutation(
    'POST',
    '/api/organizations/request-more-hours',
  )
  const { accessToken, signOut } = useAuthContext()
  const [value, setValue] = useState<number | boolean>(0)
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  const { pathname } = useLocation()

  useEffect(() => {
    if (accessToken && accessToken.exp * 1000 < Date.now()) {
      return signOut()
    }
    switch (pathname) {
      case '/dashboard':
      case '/files': {
        setValue(0)
        return
      }
      case '/organizations':
      case '/users': {
        setValue(1)
        return
      }
      case '/integrations':
      case '/configuration': {
        setValue(2)
        return
      }
      default: {
        setValue(false)
        return
      }
    }
  }, [pathname, accessToken, signOut])

  const { data, isLoading } = $profile

  if (isLoading) {
    return <Loader />
  }

  if (!data || !accessToken) {
    return null
  }

  const role = accessToken?.role

  if (!role) {
    return null
  }

  return (
    <Box sx={{ width: '100%' }}>
      {role !== 'SYSTEM_ADMIN' && role !== 'ORG_ADMIN' && (
        <Suspense fallback={<div />}>
          <UploadVideoModal onClose={setFalse} open={isTrue} />
        </Suspense>
      )}
      <AppBar
        sx={{
          zIndex: 1201,
          padding: {
            sm: '8px 8px 0 0',
            md: '8px 8px 0 0',
            lg: '8px 8px 0 48px',
          },
          borderTop: 'none',
          width: '100%',
          boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(34, 34, 34, 0.06);',
          height: '60px',
        }}
      >
        <Grid
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          container
          width="100%"
          spacing={1}
        >
          <Grid container item>
            <Stack direction="row">
              <ContentMenu toggleDrawer={drawer.toggle} />
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="inherit"
                  centered
                  sx={{
                    '.Mui-selected': {
                      fontWeight: 900,
                    },
                  }}
                >
                  {drawerItems?.[role].map(
                    (page: { title: string; to: string }, index: number) => (
                      <Tab
                        label={page.title}
                        to={page.to}
                        value={index}
                        component={InternalLink}
                        key={page.title}
                        sx={{
                          fontWeight: 500,
                          textTransform: 'capitalize',
                          marginLeft: { sm: 0, md: 0, lg: '32px' },
                        }}
                      />
                    ),
                  )}
                </Tabs>
              </Box>
            </Stack>
          </Grid>
          <Grid container item xs={11} sm={9} md={7} xl={10}>
            <Stack direction="row" ml="auto" alignItems="center" spacing={3}>
              {role === 'OWNER' && (
                <Button
                  variant="text"
                  disableFocusRipple
                  disabled={$getMoreHours.isLoading}
                  onClick={() => {
                    $getMoreHours.mutate(
                      {},
                      {
                        onSuccess: () => {
                          enqueueSnackbar(
                            t('success_notification.more_hours_request'),
                            { variant: 'success' },
                          )
                        },
                        onError: error => {
                          if (error.type === 'client_error' && error.code) {
                            enqueueSnackbar(
                              t(`error_notification.${error.code}`),
                              { variant: 'error' },
                            )
                          }
                        },
                      },
                    )
                  }}
                >
                  <Typography
                    textAlign="center"
                    textTransform="capitalize"
                    variant="button"
                    fontWeight={700}
                    fontSize={16}
                    lineHeight={1.375}
                    noWrap
                    sx={{
                      opacity: $getMoreHours.isLoading ? 0.5 : 1,
                    }}
                  >
                    {t('common.get_more_hours')}
                  </Typography>
                </Button>
              )}

              {role !== 'SYSTEM_ADMIN' && role !== 'ORG_ADMIN' && (
                <Tooltip
                  title={
                    data.fileUpload
                      ? ''
                      : `${t('upload_file.upload_forbidden')}`
                  }
                >
                  <span>
                    <Button
                      variant="text"
                      disableFocusRipple
                      disabled={!data.fileUpload}
                      sx={{ width: 140 }}
                      onClick={setTrue}
                    >
                      <Typography
                        textAlign="center"
                        textTransform="capitalize"
                        variant="button"
                        fontWeight={700}
                        fontSize={16}
                        lineHeight={1.375}
                        noWrap
                        sx={{
                          opacity: data.fileUpload ? 1 : 0.5,
                        }}
                      >
                        {t('upload_file.upload_file')}
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              )}

              <Stack margin="auto" direction="row">
                <ProfileMenu
                  avatarUrl={data.avatarUrl}
                  firstName={data.firstName}
                  lastName={data.lastName}
                  role={role}
                />
                <LanguageMenu />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </AppBar>
      {data && (
        <Hidden mdUp>
          <ContentDrawer
            open={drawer.isTrue}
            onClose={drawer.setFalse}
            onOpen={drawer.setTrue}
            userRole={role}
          />
        </Hidden>
      )}
      <main>
        <Grid container>
          <Grid item xs={12}>
            <Box width={1} minHeight="60px" />
          </Grid>

          <Grid item xs={12}>
            <Box
              width={1}
              py={{ xs: 1, md: 3 }}
              pl={{ md: 4, xs: 2 }}
              pr={{ md: 4, xs: 2 }}
            >
              {props.children}
            </Box>
          </Grid>
        </Grid>
      </main>
    </Box>
  )
}
