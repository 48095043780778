import { array, literal, number, string, type, TypeOf, union } from 'io-ts'
import { UUID } from 'io-ts-types'

export const TAuthRoles = union([
  literal('SYSTEM_ADMIN'),
  literal('ADMIN'),
  literal('EDITOR'),
  literal('OWNER'),
  literal('ORG_ADMIN'),
])

export const TAccessToken = type({
  role: TAuthRoles,

  organizationId: UUID,

  scopes: array(string),

  userId: UUID,

  iat: number,
  /**
   * Token expiration date (UNIX date)
   */
  exp: number,
})

export type AuthUserRoles = TypeOf<typeof TAuthRoles>
