import { useAuthContext } from 'app/auth'
import { ProfileCodec } from 'app/codecs'
import axios from 'axios'
import { Button } from 'components/Button'
import { format } from 'date-fns'
import { useJsonQuery } from 'lib/rest-query'

const handleDownload = async (userId: string, accessToken: any) => {
  const params = {
    skip: 0,
    limit: 100, // Limit to 100 organizations
    direction: 'DESC',
    sort: 'createdAt',
    search: '',
    userId,
  }

  const currentDate = format(new Date(), 'yyyy-MM-dd')

  try {
    const response = await axios.get(
      '/api/organizations/download-usage-report',
      {
        params: params,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    // Create a blob from the response data
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a link element and trigger download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `NWT-organisationer-total-transkribering-${currentDate}.xlsx`
    document.body.append(link)
    link.click()
    link.remove()
  } catch (error) {
    console.error('Error downloading Excel file:', error)
  }
}

export const CreateReport = () => {
  const { accessToken } = useAuthContext()
  const $profile = useJsonQuery('GET', '/api/users/me', ProfileCodec)
  const { data: profileData } = $profile

  return (
    <>
      {profileData?.role === 'ORG_ADMIN' ? (
        <Button
          withShadow
          type="submit"
          color="primary"
          variant="contained"
          onClick={() =>
            profileData?.userId &&
            handleDownload(profileData.userId, accessToken?.accessToken)
          }
          sx={{ textTransform: 'capitalize' }}
        >
          Download usage report
        </Button>
      ) : null}
    </>
  )
}
